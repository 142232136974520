<template>
  <component :is="view"></component>
</template>

<script>
import api from "../../boot/axios";
import HomeAll from "@/views/HomeAll.vue";
import ViewDashboard from "@/views/Dashboard.vue";
import CustomerHome from "@/views/CustomerProfilePage/CustomerDashboard.vue";
export default {
  name: "default",
  data() {
    return {
      currentUserRole: "",
      userRole: "",
      userData: "",
      userSet: "",
      userStatus: "",
    };
  },

  computed: {
    view() {
      if (this.currentUserRole == null) {
        return HomeAll;
      }
      if (this.currentUserRole == "Employee") {
        return ViewDashboard;
      }
      if (this.currentUserRole == "Customer") {
        return CustomerHome;
      }
    },
  },
  created() {
    this.getUserType();
  },
  methods: {
    async getUserType() {
      console.log("i am role", this.currentUserRole);

      //Get Current User
      this.userSet = this.$store.getters.getCurentUser;
      this.currentUserRole = localStorage.getItem("role");
    },
  },
};
</script>

<style></style>

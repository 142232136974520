<template>
<div>
    
    
    <Disclosure as="nav" class="bg-gray-800" v-slot="{  }">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-2">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <!-- <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" /> -->
            <!-- <XIcon v-else class="block h-6 w-6" aria-hidden="true" /> -->
          </DisclosureButton>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
              <img src="@/assets/lol_invert.png" alt="FreshLux Express" style="width:70%">
            
            
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4 mt-1">
              <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-base font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
            </div>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <button type="button" class="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
            <span class="sr-only">View notifications</span>
            <!-- <BellIcon class="h-6 w-6" aria-hidden="true" /> -->
          </button>

          <!-- Profile dropdown -->
          <Menu as="div" class="ml-3 relative">
            <div>
              <!-- menu image -->
              <!-- <MenuButton class="bg-gray-800 flex text-xl rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span class="sr-only">Open user menu</span>
                <i class="fas fa-user" style="color: white; background-color:"></i>
                
              </MenuButton> -->
            </div>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <template v-if="userSigned == true">
                <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Your Profile</a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Settings</a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Sign out</a>
                    </MenuItem>
                </MenuItems>
              </template>
              <template v-else>
                
                  <!-- <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <MenuItem v-slot="{ active }">
                    
                    <a href="/login" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']" @click="login()">Sign In</a>
                    </MenuItem>
                    
                </MenuItems> -->
                <button class="bg-buttonAccent1 hover:bg-buttonAccentHover w-[100px] text-white font-bold py-2 mx-1 rounded" type="submit" @click="login()" >Sign in</button>

              </template>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <!-- <DisclosurePanel class="sm:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</DisclosureButton>
      </div>
    </DisclosurePanel> -->
  </Disclosure>


  
</div>

</template>

<script >
import api from "../../boot/axios"
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

export default {
    name: "NavbarComponent",
    components:{
       Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems
    },
    data(){
        return{
          message : null,
          useremail: '',
          indx: 0,
          currentuser: [],
          userSigned: false,
          navigation: [
                        { name: 'Home', href: '#', current: false },
                        { name: 'About', href: '#', current: false },
                        { name: 'Contact', href: '#', current: false },
                        { name: 'Our Office', href: '#', current: false },
                        { name: 'Contact', href: '#', current: false },
                    ]
        }
    },
    props: ['routepath'],
    
    // async created(){
    //      let usr = localStorage.getItem('username');
    //     if(usr){
    //         this.message = usr;
    //         let url = '/auth/user'
    //         await api.get(url).then(res=>{
    //             this.currentuser = res.data;
    //         });
    //     }
    //     document.addEventListener("DOMContentLoaded", function(){
    //     // make it as accordion for smaller screens
    //     if (window.innerWidth < 992) {

    //       // close all inner dropdowns when parent is closed
    //       document.querySelectorAll('.navbar .dropdown').forEach(function(everydropdown){
    //         everydropdown.addEventListener('hidden.bs.dropdown', function () {
    //           // after dropdown is hidden, then find all submenus
    //             this.querySelectorAll('.submenu').forEach(function(everysubmenu){
    //               // hide every submenu as well
    //               everysubmenu.style.display = 'none';
    //             });
    //         })
    //       });

    //       document.querySelectorAll('.dropdown-menu a').forEach(function(element){
    //         element.addEventListener('click', function (e) {
    //             let nextEl = this.nextElementSibling;
    //             if(nextEl && nextEl.classList.contains('submenu')) {    
    //               // prevent opening link if link needs to open dropdown
    //               e.preventDefault();
    //               if(nextEl.style.display == 'block'){
    //                 nextEl.style.display = 'none';
    //               } else {
    //                 nextEl.style.display = 'block';
    //               }

    //             }
    //         });
    //       })
    //     }
    //     // end if innerWidth
    //     }); 
    // },
    methods:{
        
        async login(){
          this.$router.push('/login');
        }
    }
};
</script>

<style scoped>
    /* ============ desktop view ============ */
    @media all and (min-width: 992px) {
        .dropdown-menu li{ position: relative;  }
        .nav-item .submenu{ 
            display: none;
            position: absolute;
            left:100%; top:-7px;
        }
        .nav-item .submenu-left{ 
            right:100%; left:auto;
        }
        .dropdown-menu > li:hover{ background-color: #f1f1f1 }
        .dropdown-menu > li:hover > .submenu{ display: block; }
    }   
    /* ============ desktop view .end// ============ */

    /* ============ small devices ============ */
    @media (max-width: 991px) {
      .dropdown-menu .dropdown-menu{
          margin-left:0.7rem; margin-right:0.7rem; margin-bottom: .5rem;
      }
    }   
    /* ============ small devices .end// ============ */
</style>
<template>
  <div>
    <button
      class="bg-blue-300 text-white font-semibold text-xs py-2 px-1 rounded-lg"
      @click="showImage()"
    >
      Preview Image
    </button>
  </div>
</template>

<script>
export default {
  props: {
    params: Object, // Make sure this is properly defined
  },
  data() {
    return {
      showModal: true,
    };
  },
  setup(props) {
    const idCheck = props.params.data;
    console.log(idCheck, "chceking id value");

    return {
      idCheck,
    };
  },
  methods: {
    showImage() {
      this.showModal = true;
      this.$emit("update:modalData", {
        showModal: this.showModal,
        pictureId: this.idCheck.pictures,
      });
    },
  },
};
</script>

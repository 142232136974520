<template>
  <div>
    <div v-if="currentRouteName != 'login'">
      <router-view />
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>
<script>
import "./assets/tailwind.css";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-material.css"; // Optional theme CSS

export default {
  data() {
    return {};
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  methods: {},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
#app {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.ag-theme-material {
  --ag-foreground-color: rgb(0, 0, 0);
  --ag-background-color: rgb(255, 255, 255);
  --ag-header-foreground-color: rgb(0, 0, 0);
  --ag-header-background-color: #fcfcfc;
  --ag-odd-row-background-color: #fafafa;
  --ag-font-size: 14px;
  --ag-grid-size: 7px;
  --ag-list-item-height: 30px;
  --ag-font-family: Inter;
}

.ag-input-wrapper {
  border: 2px solid rgba(210, 210, 210, 0.4);
  border-radius: 3px;
}
.col-group-bg {
  background: #41ce80;
}

.ag-layout-normal {
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}

.ag-center-cols-container {
  width: 100%;
}
</style>

<style scoped></style>

<template>
  <div>
    <div class="flex gap-1 justify-between font-semibold">
      <div class="hover:bg-blue-400 py-2 px-4 rounded-md">Profile</div>
      <div class="hover:bg-blue-400 py-2 px-4 rounded-md">Orders</div>
      <div class="hover:bg-blue-400 py-2 px-4 rounded-md">Delivery Address</div>
      <div class="hover:bg-blue-400 py-2 px-4 rounded-md">Track parcel</div>
      <div class="hover:bg-blue-400 py-2 px-4 rounded-md">Change Password</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerTopNavBar",
};
</script>

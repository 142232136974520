<template>
  <div>
    <div>
      <NavbarCustomer />
    </div>
    <div class="flex bg-[#eff0f4] h-[100vh]">
      <div class="bg-gray-800 w-52 text-white font-semibold text-sm">
        <div class="mt-4">
          Hello, {{ customerData[0].firstName }} {{ customerData[0].lastName }}
        </div>
      </div>
      <!-- sidebar design -->
      <!-- <div class="sidebarCustomer">
        <div class="mt-[97px] text-[13px] font-bold">
          Hello, {{ customerData[0].firstName }} {{ customerData[0].lastName }}
        </div>
      
      </div> -->
      <!-- container -->
      <div class="w-[100%]">
        <CustomerTopNavBar class="mx-10 my-6 text-black w-5/12 text-xs" />

        <div class="bg-white p-4 mx-2">
          <ag-grid-vue
            style="width: 97%; height: 78vh; margin: auto"
            class="ag-theme-material drop-shadow-sm"
            :rowData="rowData"
            :columnDefs="columnDefs"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :enableSorting="true"
            :animateRows="true"
            :paginationNumberFormatter="paginationNumberFormatter"
            :suppressMenuHide="true"
            @cell-clicked="onCellClicked"
          ></ag-grid-vue>
          <!-- popup Image Preview  -->
          <div v-if="showPopup">
            <div class="popupContainer">
              <div class="bg-white w-[50%] h-[70%] py-7 px-8 z-30">
                <div class="w-full flex justify-end">
                  <button class="" @click="closePopup()">
                    <img
                      :src="require(`@/assets/Icon/close.svg`)"
                      alt="searchLogo"
                      class="h-[16px] w-[16px]"
                    />
                  </button>
                </div>
                <div class="text-sm dark:text-gray-600">Available Images</div>

                <hr class="mt-2" />

                <template v-for="picture in parcelPicture" :key="picture.id">
                  <template v-if="picture.id == delData.pictures">
                    <img :src="picture.picture1" alt="" class="w-40" />
                    <img :src="picture.picture2" alt="" class="w-40" />
                    <img :src="picture.picture3" alt="" class="w-40" />
                    <img :src="picture.picture4" alt="" class="w-40" />
                    <img :src="picture.picture5" alt="" class="w-40" />
                  </template>
                </template>

                <div class="mt-4 pl-5"></div>
              </div>
            </div>
          </div>
          <!-- End of Image Preview -->
          <!-- popup Timeline Popup -->
          <div v-if="showTimelinePopup">
            <div class="popupContainer">
              <div
                class="bg-white w-[50%] h-[70%] py-7 px-8 z-30 overflow-scroll"
              >
                <div class="w-full flex justify-end">
                  <button class="" @click="closePopupTimeline()">
                    <img
                      :src="require(`@/assets/Icon/close.svg`)"
                      alt="searchLogo"
                      class="h-[16px] w-[16px]"
                    />
                  </button>
                </div>
                <div class="text-sm dark:text-gray-600">Order TimeLine</div>

                <hr class="mt-2" />

                <div class="p-3">
                  <ol
                    class="relative border-l border-gray-200 dark:border-gray-700"
                  >
                    <template v-for="hawb in hawbList" :key="hawb.id">
                      <template v-if="hawb.uniqueId == timelineHawbID.hawb">
                        <template
                          v-for="parcelStatus in parcelUpdateStatus"
                          :key="parcelStatus.id"
                        >
                          <template v-if="hawb.parcelId == parcelStatus.parcel">
                            <template v-if="parcelStatus">
                              <li class="mb-5 ml-4">
                                <div
                                  class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"
                                ></div>
                                <time
                                  class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
                                  >{{ parcelStatus.timestamp }}</time
                                >
                                <p
                                  class="mb-1 text-base font-normal text-gray-500 dark:text-gray-400"
                                >
                                  {{ parcelStatus.status }}
                                </p>
                              </li>
                            </template>
                          </template>
                        </template>
                      </template>
                    </template>
                  </ol>
                </div>

                <!-- <template v-for="hawb in hawbList" :key="hawb.id">
                  <template v-if="hawb.uniqueId == timelineHawbID.hawb">
                    <template
                      v-for="parcelStatus in parcelUpdateStatus"
                      :key="parcelStatus.id"
                    >
                      <template v-if="hawb.parcelId == parcelStatus.parcel">
                        <template v-if="parcelStatus">
                          <div>
                            {{ parcelStatus.status }}
                          </div>
                        </template>
                      </template>
                    </template>
                  </template>
                </template> -->

                <div class="mt-4 pl-5"></div>
              </div>
            </div>
          </div>
          <!-- End of Timeline Popup -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarCustomer from "@/components/NavbarCustomer.vue";
import CustomerTopNavBar from "@/components/CustomerTopBar/CustomerTopNavBar.vue";
import { AgGridVue } from "ag-grid-vue3";
import api from "../../../boot/axios.js";

import previewImage from "@/components/Customer/multipleImageButton.vue";
export default {
  data() {
    return {
      user: null,
      parcelData: [],
      searchText: "",
      parcelPicture: [],
      customerData: null,
      showPopup: false,
      hawbList: [],
      delData: {},
      bagBoxList: [],
      manifestList: [],
      timelineHawbID: {},
      parcelUpdateStatus: [],
      showTimelinePopup: false,
      rowData: [], // Will hold the data for the grid
      columnDefs: [
        {
          headerName: "HAWB",
          field: "hawb",
          width: 150,
          sortable: true,
          floatingFilter: true,
          filter: "agTextColumnFilter",
          cellStyle: { "text-align": "start" },
        },
        {
          headerName: "ITEM #",
          field: "item",
          width: "100%",
          sortable: true,
          cellStyle: { "text-align": "start" },
        },
        {
          headerName: "Parcel Date",
          field: "parcel_date",
          width: "200px",
          sortable: true,
          cellStyle: { "text-align": "start" },
        },
        {
          headerName: "Hub",
          field: "hub",
          width: "100%",
          cellStyle: { "text-align": "start" },
        },
        {
          headerName: "Description",
          field: "description",
          width: 150,
          cellStyle: { "text-align": "start" },
        },
        {
          headerName: "Courier No.",
          field: "courier_no",
          width: "150px",
          cellStyle: { "text-align": "start" },
        },
        {
          headerName: "Goods value",
          field: "goods_value",
          width: 150,
          sortable: true,
          cellStyle: { "text-align": "start" },
        },
        {
          headerName: "Chargeable Wt.",
          field: "chargeable_wt",
          width: "150px",
          cellStyle: { "text-align": "start" },
        },
        {
          headerName: "Notes",
          field: "notes",
          width: "150px",
          cellStyle: { "text-align": "start" },
        },
        {
          headerName: "Status",
          field: "status",

          width: 150,
          cellStyle: { textAlign: "start" },
        },
        {
          headerName: "Pictures",
          field: "pictures",
          cellRenderer: "previewImage",
          width: 150,
          cellStyle: { textAlign: "start" },
        },
      ],
    };
  },

  components: {
    NavbarCustomer,
    CustomerTopNavBar,
    AgGridVue,
    previewImage,
  },

  created() {
    this.user = {
      name: localStorage.getItem("name"),
      role: localStorage.getItem("role"),
      email: localStorage.getItem("email"),
      id: localStorage.getItem("id"),
    };

    this.populateGridData();
  },

  methods: {
    async populateGridData() {
      // Fetching Customer Data
      await api
        .get(`api/customer/?user=${this.user.id}`)
        .then((res) => {
          this.customerData = res.data;
          console.log(this.customerData[0].id);
        })
        .catch((err) => {
          console.log(err);
        });

      // Fetching Parcel Data for the customer
      await api
        .get(`api/parcel/?cusId=${this.customerData[0].id}`)
        .then((res) => {
          this.parcelData = res.data;
          console.log(this.parcelData, "checking user dATA");
        })
        .catch((err) => {
          console.log(err);
        });
      // Fetching ParcelPicture Data for the customer
      await api
        .get(`api/picture_parcel/`)
        .then((res) => {
          this.parcelPicture = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      // Fetching All the Hawb LIST
      await api
        .get(`api/hawb/`)
        .then((res) => {
          this.hawbList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      // Fetching All Bag Boxes
      await api
        .get(`api/bag_box_manifest/`)
        .then((res) => {
          this.bagBoxList = res.data.results;
        })
        .catch((err) => {
          console.log(err);
        });
      // Fetching All Manifest
      await api
        .get(`api/manifest2/`)
        .then((res) => {
          this.manifestList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      // Fetching All Status
      await api
        .get(`api/parcel_status_update/`)
        .then((res) => {
          this.parcelUpdateStatus = res.data;
          console.log(res.data, "checking ParcelUpdate box");
        })
        .catch((err) => {
          console.log(err);
        });

      this.rowData = this.parcelData.map((parcel) => {
        const matchingHawb = this.hawbList.find(
          (hawb) => hawb.parcelId === parcel.id
        );

        const matchingPicture = this.parcelPicture.find(
          (picture) => picture.parcelId === parcel.id
        );
        return {
          hawb: matchingHawb ? matchingHawb.uniqueId : "",
          item: parcel.quantity,
          parcel_date: parcel.dateReceived,
          hub: parcel.hubWarehouse,
          description: parcel.description,
          courier_no: parcel.originTrackingNumber,
          goods_value: parcel.value,
          chargeable_wt: parcel.volumectricWeight,
          notes: parcel.notes ? parcel.notes : "",
          status: parcel.individual_status
            ? parcel.individual_status
            : "Not Ready",
          pictures: matchingPicture.id,
        };
      });
    },
    onCellClicked(e) {
      // console.log(e.column?.colId, "hrer");
      if (e.column?.colId === "pictures") {
        this.delData = e.data;
        this.showPopup = !this.showPopup;
      }
      if (e.column?.colId === "hawb") {
        this.timelineHawbID = e.data;
        console.log(this.timelineHawbID, "checkValue");
        this.showTimelinePopup = !this.showTimelinePopup;
      }
    },

    closePopup() {
      this.delData = {};
      this.showPopup = !this.showPopup;
    },
    closePopupTimeline() {
      this.timelineHawbID = {};
      this.showTimelinePopup = !this.showTimelinePopup;
    },
  },
};
</script>

<style scoped>
.personDetailsButton {
  border-radius: 5px;
  color: rgba(106, 106, 106, 0.346);
  border: solid rgba(106, 106, 106, 0.346) 1px;
  margin-top: 20px;
  font-size: 12px;
  padding: 10px 20px;
}
.popupContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.242);
}
</style>

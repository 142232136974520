<template>
  <div class="home h-[100vh] bg-slate-300">
    <Sidebar/>
    <div :style="{ 'margin-left': sidebarWidth }">
      <div class=" grid grid-rows-2 gap-4 h-[99vh] mx-2">
        <div class=" row-span-1 bg-slate-300">
          <div class=" grid grid-cols-3 gap-6 h-full">
            <div class=" col-span-1 bg-white pt-8">
              <div class="image-cropper" style="margin:0 auto;">
                <img :src="userPic" alt="Not Found">
              </div>
              <p class=" text-2xl font-mono mt-4">Name: {{userData.first_name}} {{userData.last_name}}</p>
              <p class=" text-2xl font-mono">Email: {{userData.email}}</p>
              <p class=" text-2xl font-mono">Phone: {{userData.Phone}}</p>
              <p class=" text-2xl font-mono">Hub WareHouse: {{userData.location}}</p>
            </div>
            <div class=" col-span-2 bg-white">
              <p class=" font-bold text-xl font-mono mt-4">Parcel Registerd Monthly</p>
              <div class="grid grid-cols-12">
                  <div class="col-span-1"></div>
                  <div class="col-span-10">
                    <BarChart :chart-data="chartDataP"/>
                  </div>
                  <div class="col-span-1"></div>
              </div>
            </div>
          </div>
        </div>
        <div class=" row-span-1 bg-slate-300">
          <div class=" grid grid-cols-2 gap-6 h-full">
            <div class=" col-span-1 bg-white p-4">
              <p class=" font-bold text-xl font-mono ml-10">Parcel Locations</p>
              <div class="grid grid-cols-6">
                <div class="col-span-4">
                  <PieChart :chart-data="chartData" />
                </div>
                <div class="col-span-1 text-left">
                  <p class=" text-xl font-bold font-sans ml-2 mt-28">Hub:</p>
                  <p class=" text-xl font-bold font-sans ml-2 mt-2">Transit:</p>
                  <p class=" text-xl font-bold font-sans ml-2 mt-2">Custom:</p>
                  <p class=" text-xl font-bold font-sans ml-2 mt-2">GRN:</p>
                  <p class=" text-xl font-bold font-sans ml-2 mt-2">Un:</p>
                </div>
                <div class="col-span-1 text-left">
                  <p class=" text-xl font-bold font-sans  mt-28">{{varr[0].toString().padStart(3, '0')}}</p>
                  <p class=" text-xl font-bold font-sans  mt-2">{{varr[1].toString().padStart(3, '0')}}</p>
                  <p class=" text-xl font-bold font-sans  mt-2">{{varr[2].toString().padStart(3, '0')}}</p>
                  <p class=" text-xl font-bold font-sans  mt-2">{{varr[3].toString().padStart(3, '0')}}</p>
                  <p class=" text-xl font-bold font-sans  mt-2">{{varr[4].toString().padStart(3, '0')}}</p>
                </div>
              </div>
            </div>
            <div class=" col-span-1 bg-white p-4">
              <p class=" font-bold text-xl font-mono ml-10">Manifest Locations</p>
              <div class="grid grid-cols-8">
                <!-- <div class="col-span-1 text-left">
                  <p class=" text-xl font-bold font-sans ml-6 mt-36">Open:</p>
                  <p class=" text-xl font-bold font-sans ml-6 mt-2">Closed:</p>
                  <p class=" text-xl font-bold font-sans ml-6 mt-2">On Transit:</p>
                </div>
                <div class="col-span-1 text-left">
                  <p class=" text-xl font-bold font-sans ml-2 mt-36">{{varr3[0].toString().padStart(3, '0')}}</p>
                  <p class=" text-xl font-bold font-sans ml-2 mt-2">{{varr3[1].toString().padStart(3, '0')}}</p>
                  <p class=" text-xl font-bold font-sans ml-2 mt-2">{{varr3[2].toString().padStart(3, '0')}}</p>
                </div> -->
                <div class="col-span-1"></div>
                <div class="col-span-6">
                  <BarChart :chart-data="chartDataM" />
                </div>
                <div class="col-span-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/sidebar/Sidebar'
import { sidebarWidth } from '@/components/sidebar/state'
import PieChart from '../components/pieChart.ts'
import BarChart from '../components/barChart.ts'
import DoughnutChart from '../components/doughnutChart.ts'
import api from '../../boot/axios.js'
// @ is an alias to /src
export default {
  name: 'HomeView',
  components: { Sidebar, PieChart, BarChart, DoughnutChart },
  setup() {
    return { sidebarWidth }
  },
  data() {
            return {
                chartData : {
                    labels: ['Hub', 'Transit', 'Custom', 'GRN', 'Un'],
                    datasets: [
                        {
                            backgroundColor: ['#dc346c', '#fcdc9c', '#391d9d', '#aa579f', '#85e4ed'],
                            data: [2,2,2,2,2,2]
                        }
                    ]
                },
                chartDataP : {
                  labels: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                  ],
                  datasets: [
                    {
                      label: 'Parcel',
                      backgroundColor: ['#42c9bb'],
                      data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
                    }
                  ]
                },
                chartDataM : {
                  labels: [
                    'Open',
                    'Close',
                    'On Transit',
                  ],
                  datasets: [
                    {
                      label: 'Manifest',
                      backgroundColor: '#f98568',
                      data: [40,30,12]
                    },
                  ]
                },
                dataParcel:[],
                dataManifest:[],
                varr:[0,0,0,0,0],
                varr3:[0,0,0],
                varr2:[0,0,0,0,0,0,0,0,0,0,0,0],
                id: localStorage.getItem('id'),
                userData:[],
                userPic: null,
            }
        },
        created() {
            this.getDataI();
            this.getData();
        },

        methods: {
            async getDataI(){
              // console.log('id',this.id);
               await api
                    .get(`auth/user/${localStorage.getItem('id')}/`)
                    .then(response => {
                        this.userData = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
              await api
                    .get(`api/u_picture/?cusId=${localStorage.getItem('id')}`)
                    .then(response => {
                        this.userPic = response.data[0].picture
                    })
                    .catch(error => {
                        console.log(error)
                    })
                  
            },
            async getData(){
                await api
                    .get(`api/parcel/`)
                    .then(response => {
                        this.dataParcel = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
                  await api
                    .get(`api/manifest2/`)
                    .then(response => {
                        this.dataManifest = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
                for (let i = 0; i < this.dataParcel.length; i++) {
                    if (this.dataParcel[i].location==null) {
                        this.varr[4]++;
                    }
                    else if (this.dataParcel[i].location=='hub') {
                        this.varr[0]++;
                    }
                    else if (this.dataParcel[i].location=='transit') {
                        this.varr[1]++;
                    }
                    else if (this.dataParcel[i].location=='custom') {
                        this.varr[2]++;
                    }
                    else if (this.dataParcel[i].location=='grn') {
                        this.varr[3]++;
                    }
                    let a = parseInt(this.dataParcel[i].created_at.substring(5, 7))
                    this.varr2[a-1]++;
                }
                for (let i = 0; i < this.dataManifest.length; i++) {
                  // console.log(this.dataManifest[i].status);
                  if (this.dataManifest[i].status==true) {
                    this.varr3[0]++;
                  }
                  else{
                    if (this.dataManifest[i].locationStatus=="Ready for Shipment") {
                      this.varr3[1]++;
                    }
                    else{
                      this.varr3[2]++;
                    }
                  }
                }
                // console.log(this.varr3);
                this.chartData.datasets[0].data=this.varr;
                this.chartDataP.datasets[0].data=this.varr2
                this.chartDataM.datasets[0].data=this.varr3
            }
        }
}
</script>
<style scoped>
.image-cropper {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

img {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}
</style>
import { defineComponent, h, PropType } from 'vue'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Plugin
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

export default defineComponent({
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    // chartId: {
    //   type: String,
    //   default: 'bar-chart'
    // },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          formatter: function(value) {
            if(value==0){
              return '';
            }
            if(isNaN(value)){
              return '';
            }
            return Math.round(value*10)/10;
          }
        },
      }
    }

    return () =>
      h(Bar, {
        chartOptions,
        chartId: props.chartId,
        width: props.width,
        height: props.height,
        cssClasses: props.cssClasses,
        styles: props.styles,
        plugins: props.plugins
      })
  }
})

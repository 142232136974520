import { createRouter, createWebHistory } from "vue-router";

import DefaultHome from "../views/DefaultHome.vue";
import Login from "../views/login.vue";

function guardMyroute(to, from, next) {
  var isAuthenticated = false;
  //this is just an example. You will have to find a better or
  // centralised way to handle you localstorage data handling
  if (localStorage.getItem("email") != null) {
    //console.log(localStorage.getItem('email'))
    //console.log('user is here')
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }

  if (isAuthenticated) {
    next(); // allow to enter route
  } else {
    next("/"); // go to '/login';
  }
}

const routes = [
  {
    path: "/",
    name: "default",
    component: DefaultHome,
    // beforeEnter : guardMyroute,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/register.vue"),
  },
  {
    path: "/register_admin",
    name: "registerAdmin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/registerAdmin.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/parcel",
    name: "Parcel",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Parcel.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/parcel_all",
    name: "ParcelAll",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ParcelAll.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/parcel_add",
    name: "ParcelAdd",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ParcelAdd.vue"),
    beforeEnter: guardMyroute,
  },
  //
  // {
  //   path: "/parcel_add",
  //   name: "ParcelAdd",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Parcel/parcelAdd.vue"),
  //   beforeEnter: guardMyroute,
  // },
  {
    path: "/u_parcel",
    name: "ParcelU",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ParcelU.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/manifest",
    name: "Manifest",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ManifestView.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/manifest_hub",
    name: "ManifestHub",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ManifestViewHub.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/manifest_detail/:id",
    name: "ManifestDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ManifestDetail.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/manifest_detail_hub/:id",
    name: "ManifestDetailHub",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ManifestDetailHub.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/inspection",
    name: "Inspection",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Inspection.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/rack",
    name: "Rack",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Rack.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/settings",
    name: "Settings",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Settings.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/location",
    name: "Location",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Location.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/grn_pre",
    name: "PreGRN",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PreGRN.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/grn",
    name: "GRN",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/GRN.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/test",
    name: "Test",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Test.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/type",
    name: "Type",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Type.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/hawb",
    name: "hawb",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HWAB.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/pickup",
    name: "pickup",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PickUp.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/rrwb",
    name: "rrwb",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RRWB.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/report_parcel",
    name: "ReportParcel",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ReportParcel.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/bag_box/:id",
    name: "BagBox",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BagBox.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/customer_dashboard",
    name: "CustomerDashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/CustomerProfilePage/CustomerDashboard.vue"
      ),
    beforeEnter: guardMyroute,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
